<template>
  <CRow>
    <CCol col="12" sm="12">

    </CCol>
  </CRow>
</template>

<script>

export default {
  name: 'dashboard',
  components: {
  },
  data () {
    return {
    }
  },
  mounted: function () {
  }
}
</script>
